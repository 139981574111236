export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://api.monsuke.jp/',
  paymentApiUrl:'https://payments.monsuke.jp/',
  firebaseConfig: {
      apiKey: "AIzaSyCf8IUD0epjC1gLKY-bQdXjbexZxPuTnV0",
      projectId: "kaigo-production",
      appId: "1:525386626468:web:48b008803faea4c41d7d31"
  },
  bugsnag: '96d222f578c5f3e0eb8e71c5f824a146',
};

// export const environment = {
//   production: false,
//   hmr: false,
//   // apiUrl: 'http://localhost:80/',
//   apiUrl: 'https://qa.api.monsuke.jp/',
//   paymentApiUrl:'https://be.monsuke.jp/',
//   firebaseConfig: {
//       apiKey: "AIzaSyChdRuL4QZLl8na3qHfhLugd5zMW8vzAdQ",
//       projectId: "kaigo-qa",
//       appId: "1:956516596359:web:b9e0eed79604013b8bd622"
//   },
//   bugsnag: '96d222f578c5f3e0eb8e71c5f824a146',
// };