<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    class="dropdown-item d-flex align-items-center menu-text-hover"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    class="dropdown-item d-flex align-items-center menu-text-hover"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

   <!-- item without url -->
   <p
   class="dropdown-item d-flex align-items-center menu-text-hover"
   [ngClass]="item.classes"
   *ngIf="!item.url && !item.externalUrl"
   (click)="action(item?.id)"
 >
   <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </p>

  <ng-template #itemContent (click)="getAnnouncementCount()">
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span *ngIf="item?.id=='logout'" style="width:23px"></span>
    <img *ngIf="!item?.icon && item?.custom_icon?.inactive" [src]="item?.custom_icon?.inactive" class="custom-icon-inactive" alt="">
    <img *ngIf="!item?.icon && item?.custom_icon?.active" [src]="item?.custom_icon?.active" class="custom-icon-active" alt="">
    <span [translate]="item.translate">{{ item.title |translate}}</span>
    <button *ngIf="item?.id=='announcement'&&announcementUnreadCount" fxLayout="row" fxLayoutAlign="center center"  class="unread-data-count">{{announcementUnreadCount}}</button>
  </ng-template>
</ng-container>
