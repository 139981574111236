import { Component, HostBinding, Input } from '@angular/core';
import { GeneralService } from 'app/auth/service';
import { AnnouncementService } from 'app/care-provider/components/announcement/announcement.service';
@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  public lang
  announcementUnreadCount:number=0
  constructor(
    private generalService:GeneralService,
    private announcementService:AnnouncementService){
    this.generalService.currentLanguage.subscribe(lang=>this.lang=lang)
  }
  @Input()
  item: any;

  ngOnInit(): void {
    this.announcementService.onApiDataChange.subscribe(res => {
      this.announcementUnreadCount = this.announcementService.apiData;
    //   console.log("announcementUnreadCount",this.announcementUnreadCount)
    });
  }

  action(key){
    this.generalService.action.next(key)
  }

  getAnnouncementCount(){
    this.announcementService.getAnnouncementCount()
  }

}
