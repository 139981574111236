import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, GeneralService } from 'app/auth/service';
import { ApiConstant } from 'app/shared/api-constents';
import Japanese from 'flatpickr/dist/l10n/ja'
import moment from 'moment';
import { FlatpickrOptions, Ng2FlatpickrComponent } from 'ng2-flatpickr';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-interview-result-report',
  templateUrl: './interview-result-report.component.html',
  styleUrls: ['./interview-result-report.component.scss']
})
export class InterviewResultReportComponent implements OnInit {
  private unsubscribe = new Subject<void>();
  lang: string
  buttonLoader: boolean = false;
  reportForm: FormGroup;
  approveForm: FormGroup;
  rejectForm: FormGroup;
  care_provider: any
  applicationId:any
  startDatePickerOptions: FlatpickrOptions = {
    locale: Japanese.ja,
    dateFormat: 'Y-m-d',
    allowInput: false,
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    formatDate: (date: string) => {
      return moment(date).format("YYYY年  MM月  DD日");
    }
  };
  constructor(
    public activeModal: NgbActiveModal,
    public generalService: GeneralService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private apiService: ApiService,
    private toaster: ToastrService
  ) {
    this.reportForm = this.formBuilder.group({
      recruitment_status: [null, [Validators.required]],
    })
    this.approveForm = this.formBuilder.group({
      employment_status: [null, [Validators.required]],
      type_of_occupation: [null, [Validators.required]],
      start_date: [null, [Validators.required]]
    })
    this.rejectForm = this.formBuilder.group({
      reason_for_rejection: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.generalService.currentLanguage.subscribe(lang => this.lang = lang);
  }

  onCancel(): void {
    this.activeModal.close(false);
  }
  onConfirm(approvalModal, rejectionModal): void {
    const modalRef = this.modalService.open(this.reportForm.value.recruitment_status == "hired" ? approvalModal : rejectionModal,
      {
        centered: true,
        size: "md",
      });
    modalRef.result.then((res) => {
      if (res === 'confirm') {
        let value
        if (this.reportForm.value.recruitment_status == "hired") {
          value = {
            applicationId: this.applicationId,
            employment_status: this.approveForm.value?.employment_status,
            start_date: (moment(this.approveForm.value?.start_date[0].toString()).utc().toISOString() as any),
            job_report_status: this.reportForm.value?.recruitment_status,
            occupation: this.approveForm.value?.type_of_occupation
          }
        } else {
          value = {
            applicationId: this.applicationId,
            job_report_status: this.reportForm.value?.recruitment_status,
            rejectionReason: this.rejectForm.value?.reason_for_rejection
          }
        }
        console.log("data.......", value)
        this.postInterviewResult(value)
      }
    })
  }

  postInterviewResult(value) {
    this.apiService.ExecutePost(`${this.apiService.baseUrl + ApiConstant.fulltimeJobInterviewAcceptance}`, value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        console.log(response)
        this.toaster.success('採用面談結果を送信しました')
        this.activeModal.close(true);
        // window.history.back()
      }, (error) => {
        console.log(error)
        this.toaster.error('採用面談結果の送信に失敗しました')
        this.activeModal.close(false);
      })
  }


}
