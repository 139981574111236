import { InMemoryDbService } from 'angular-in-memory-web-api';
import { ChatWidgetFakeData } from '@fake-db/chat-widget.data';
import { ChatFakeData } from '@fake-db/chat.data';
export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {


      // Chat
      'chat-profileUser': ChatFakeData.profileUser,
      'chat-contacts': ChatFakeData.contacts,
      'chat-chats': ChatFakeData.chats,

      // Chat Widget
      'chat-widget-data': ChatWidgetFakeData.data,

    };
  }
}
