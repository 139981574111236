import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { ApiConstant } from 'app/shared/api-constents';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import moment from 'moment'
import { AnnouncementService } from 'app/care-provider/components/announcement/announcement.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  profileData = new BehaviorSubject(null);
  private refresh_token_load: Boolean = false
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private announcementService: AnnouncementService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */


  getProfileData(redirect?: boolean, from_login?: boolean) {
    this.apiService
      .ExecuteGet(this.apiService.baseUrl + ApiConstant.profile)
      .subscribe(
        (response: any) => {

          if (response?.result?.role != 'care_provider') {
            return
          }
          this.currentUserSubject.next(response?.result);
          localStorage.setItem('currentUser', JSON.stringify(response?.result));
          this.profileData.next(response?.result);
          // if(from_login){
          //   setTimeout(() => {  
          //     this._toastrService.success(
          //       'You have successfully logged in ',null,
          //       { toastClass: 'toast ngx-toastr', closeButton: true }
          //     );
          //   }, 100);
          // }
          this.announcementService.getAnnouncementCount()
          if (redirect) {
            this.router.navigate(['care_provider'])
          }

        },
        (error: any) => {
          this.logout()
        }
      );
  }

  updateProfileResponse(profile) {
    this.currentUserSubject.next(profile);
    localStorage.setItem('currentUser', JSON.stringify(profile));
    this.profileData.next(profile);
  }

  set setToken(token: any) {
    localStorage.setItem('kaigoToken', token);
  }

  get getToken() {
    if (localStorage.getItem('kaigoToken')) {
      return localStorage.getItem('kaigoToken');
    } else {
      return null;
    }
  }
  checkTokenValidation() {
    let expiryTime = this.getTokenExpiryTime
    if (expiryTime) {
      let current_time = new Date()
      let token_expiry_time = new Date(expiryTime)
      let is_after = moment(current_time).isAfter(token_expiry_time)
      if (is_after) {
        this.fetchNewFireBaseAccessToken()
      } else {
        return
      }
    }
    else {
      this.fetchNewFireBaseAccessToken()
    }
  }

  setTokenExpiryTime() {
    let current_time = new Date()
    current_time.setMinutes(current_time.getMinutes() + 45)
    localStorage.setItem('tokenExpiryTime', current_time.toISOString());
  }

  get getTokenExpiryTime() {
    if (localStorage.getItem('tokenExpiryTime')) {
      return localStorage.getItem('tokenExpiryTime');
    } else {
      return null;
    }
  }

  async fetchNewFireBaseAccessToken(redirect?: boolean) {
    let expiryTime = this.getTokenExpiryTime
    let current_time = new Date().getTime()
    let token_expiry_time = new Date(expiryTime).getTime()
    if (current_time < token_expiry_time) {
      return
    }
    let refresh_token = localStorage.getItem('refreshToken')
    let access_token = localStorage.getItem('kaigoToken')
    if (!this.refresh_token_load && refresh_token && access_token) {
      this.refresh_token_load = true
      const url = ApiConstant.firebase_refresh_token_api?.replace('{{fb_api_key}}', environment?.firebaseConfig?.apiKey)
      let url_encoded = new URLSearchParams()
      url_encoded.set('grant_type', 'refresh_token')
      url_encoded.set('refresh_token', refresh_token)
      this.apiService
        .ExecutePost(url, url_encoded)
        .subscribe(
          (response: any) => {
            this.setTokenExpiryTime()
            localStorage.setItem('kaigoToken', response?.access_token);
            localStorage.setItem('refreshToken', response?.refresh_token);
            this.refresh_token_load = false
            this.getProfileData(redirect)
          },
          (error: any) => {
            this.refresh_token_load = false
            this.logout()
          }
        );
    }
  }

  logout() {
    localStorage.clear();
    // localStorage.removeItem("uid")
    // localStorage.removeItem("currentUser")
    // localStorage.removeItem("refreshToken")
    // localStorage.removeItem("kaigoToken")
    // localStorage.removeItem("tokenExpiryTime")
    this.router.navigate(['/'])
  }

  verifiyProfile=(): boolean=>{
    let valid = this.requiredDataValidator(this.currentUserSubject.value?.companyDetail,['address','buisiness_overview','city','prefecture','officeName','cordinate','how_to_access'])
    if(!this.currentUserSubject?.value?.photoList||!this.currentUserSubject?.value?.photoList?.length){
      valid=false
    }
    return valid
  }

  requiredDataValidator = (body: any, fields: string[]): boolean => {
    let required: string[] = []
    fields.forEach((key) => {
      if ([undefined, '', null].includes(body[key])) {
        required.push(key)
      }
    })
    return required.length ? false : true
  }
}
