<div class="main-padding" style="background-color: #f7f7f7;">
    <h4 class="back-btn" (click)="back()"><img src="/assets/icons/back-icon-grey.svg"
        alt=""><span>{{"return_to_draft"|translate}}</span></h4>
<!-- card -->
<div class="preview-container" >
    <div class="card-preview">
        <div class="content-preview">
            <div class="nav-fixed">
                <div class="icon-div-left" fxLayout="row" fxLayoutAlign="center center">
                    <img class="icon-nav" src="/assets/icons/time-preview.svg" alt="">
                    <img class="icon-nav" src="/assets/icons/location-preview.svg" alt="">
                </div>
                <div class="icon-div-right" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
                    <img class="icon-nav" src="/assets/icons/network-preview.svg" alt="">
                    <img class="icon-nav" src="/assets/icons/connection-preview.svg" alt="">
                    <img class="icon-nav" src="/assets/icons/battery-preview.svg" alt="">
                </div>
            </div>
           <div class="img-div">
                <div style="position: relative;">
                <img class="img-head" [src]="profile?.photoList[0]?.url?profile?.photoList[0]?.url:'/assets/images/avatars/dummy-image.jpg'" onError="/assets/images/avatars/dummy-image.jpg">
                <img class="back-btn-inside" src="/assets/icons/back-preview.svg" alt="">
                <img class="img-count" src="/assets/icons/img-count-preview.svg" alt="">
                </div>
                
           </div>
            <div class="section-1">
                <h4 class="job-title" style="margin-top: 14px;text-overflow: ellipsis;overflow: hidden;width:85%;">{{jobDetails?.job_title?jobDetails?.job_title:'【北海道札幌市】ショートステイでの介護業務 (主に入浴介助) お願いします！'}}</h4>
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <!-- caretype -->
                    <div class="d-flex flex-wrap" style="gap:4px;overflow: hidden;max-width: 84%;">
                    <div class="tag-preview" *ngFor="let car_type of jobDetails?.careType">{{car_type}}</div>
                    </div>
                    <!-- daily salary -->
                    <div class="job-title" style="width: 53px;white-space: nowrap;">{{jobDetails?.job_dates[0]?.daily_wage | number}} 円</div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" style="width: 100%;margin-top: 10px;">
                    <!-- start time date break time -->
                    <h4 class="sub-head-preview">
                        {{jobDetails?.job_dates[0]?.start_date | date:'MM月 dd日'}} ({{weekDay(jobDetails?.job_dates[0]?.start_date)}}) {{jobDetails?.job_dates[0]?.start_date | date : 'HH:mm'}} ~ {{jobDetails?.job_dates[0]?.end_date | date : 'HH:mm'}} （休憩{{jobDetails?.job_dates[0]?.break_time}}）
                    </h4>
                    <h4 class="sub-head-preview">{{profile?.companyDetail?.address?profile?.companyDetail?.address:'北海道札幌市東雲町3丁目916番地17号'}}</h4>
                    <div class="d-flex justify-content-between" style="width:100%;">
                        <div  fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" style="width: 70% !important;">
                            <!-- transportation expense -->
                            <h4 class="sub-head-preview">交通費 {{jobDetails?.payment_amount}}円</h4>
                            <h4 class="sub-head-preview" style="color:#30303073;text-overflow: ellipsis;overflow: hidden;width:90%;white-space:nowrap">
                                {{profile?.companyDetail?.officeName?profile?.companyDetail?.officeName:'住宅型有料老人ホームひまわり倶楽部'}}
                            </h4>
                        </div>
                        <button class="btn-preview">募集人数 {{jobDetails?.applicant_numbers}}人</button>
                    </div>
                </div>
            </div>
            <hr class="seperator" *ngIf="jobDetails?.job_dates?.length>1">
            <div class="section-1" *ngIf="jobDetails?.job_dates?.length>1">
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100% !important;">
                    <h4 class="job-title mb-2">ほかの日時も募集しています</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100% !important;" *ngFor="let dates of jobDetails?.job_dates?.slice(1)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxLayout="column" fxLayoutAlign="center center"  class="box-date">
                                <h4 style="font-size: 12.6px !important;font-weight: bold !important;margin: 0px !important;">{{dates?.start_date | date : 'MM/dd'}}</h4>
                                <h4 style="font-size: 11.02px !important;font-weight: bold !important;margin: 0px !important;">{{weekDay(dates?.start_date)}}</h4>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                                <h4 style="font-size: 11.02px !important;">
                                    {{dates?.start_date | date : 'HH:mm'}} ~ {{dates?.end_date | date : 'HH:mm'}}
                                </h4>
                                <h4 style="font-size: 11.02px;font-weight: bold !important;">{{dates?.daily_wage | number}} 円</h4>
                            </div>
                        </div>
                        <img src="/assets/icons/right-arrow-grey.svg" class="right-arrow" alt="">
                    </div>
                </div>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <div fxLayout="row" fxLayoutAlign="start center" class="nav-bar-small">
                    <h6 class="nav-content"  style="border-bottom: 1px solid #FAAA14 !important;color:#FAAA14 !important; height: 100%; padding: 10px;">事業所概要</h6>
                    <h6 class="nav-content" style="border-bottom: 1px solid #cac7c7ec;padding: 10px;">仕事の内容</h6>
                    <h6 class="nav-content" style="border-bottom: 1px solid #cac7c7ec;padding: 10px;">1日の流れ</h6>
                    <h6 class="nav-content" style="border-bottom: 1px solid #cac7c7ec;padding: 10px;">待遇</h6>
                    <h6 class="nav-content" style="border-bottom: 1px solid #cac7c7ec;padding: 10px;">応募条件</h6>
                </div>
                <!-- business overview from profile details -->
                <h4 class="job-title mb-1 mt-2">事業所概要</h4>
                <textarea class="preview-textarea font-sm-preview" #overview readonly style.height="{{overview?.scrollHeight}}px !important">{{profile?.companyDetail?.buisiness_overview}}</textarea>

                <div class="pt-1" style="border-top: 1px solid #f3f3f3ec;width: 100%;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px">
                    <img class="down-arrow" src="../../../../../assets/icons/right-arrow-grey.svg" alt="">
                    <span class="nav-content" style="cursor: pointer;">もっとみる</span>
                </div>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- job description -->
                <h4 class="job-title mb-1">仕事の内容</h4>
                <textarea class="preview-textarea font-sm-preview" #jobContent readonly style.height="{{jobContent?.scrollHeight}}px !important">{{jobDetails?.job_content}}</textarea>
                <div class="pt-1" style="border-top: 1px solid #f3f3f3ec;width: 100%;">
                    <!-- careitems -->
                <h4 class="job-title mb-1">介助項目</h4>
                    <div class="d-flex flex-wrap" style="gap:4px;overflow: hidden;width: 100%;">
                    <div class="tag-preview" *ngFor="let item of jobDetails?.care_items">{{item}}</div>
                    </div>   
                </div>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- daily flow -->
                <h4 class="job-title mb-1">1日の流れ</h4>
                <textarea class="preview-textarea font-sm-preview" #dailyFlow readonly style.height="{{dailyFlow?.scrollHeight}}px !important">{{jobDetails?.daily_flow}}</textarea>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- treatement -->
                    <h4 class="job-title mb-1">待遇</h4>
                    <div class="d-flex flex-wrap" style="gap:4px;overflow: hidden;width: 100%;">
                        <div class="tag-preview" *ngFor="let treat of jobDetails?.treatment">{{treat}}</div>
                    </div>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- qualification documents held -->
                    <h4 class="job-title mb-1">応募条件資格</h4>
                    <div class="d-flex flex-wrap" style="gap:4px;overflow: hidden;width: 100%;">
                        <div class="tag-preview" *ngFor="let qual of jobDetails?.qualifications">{{qual}}</div>
                    </div>  
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- to bring -->
                <h4 class="job-title mb-1">持ち物</h4>
                <textarea class="preview-textarea font-sm-preview" #toBring readonly style.height="{{toBring?.scrollHeight}}px !important">{{jobDetails?.to_bring}}</textarea>
            </div>
            <hr class="seperator">
            <div class="section-1">
                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;" >
                <h4 class="job-title">労働条件通知書</h4>
                <img class="down-arrow" src="../../../../../assets/icons/right-arrow-grey.svg" alt="">
                </div>
            </div>
            <!-- <hr class="seperator">
            <div class="section-1">
                <img src="/assets/icons/map-dummy-preview.svg" class="map-dummy mb-2" alt="">
                <h6 class="font-sm-preview">北海道札幌市 XXXX 区 XXX 条 XX 丁目 XX 番 XX 号</h6>
                <h6 class="font-sm-preview">・”XXXXXX” 駅から徒歩 “XX” 分</h6>
                <h6 class="font-sm-preview">・”XXXXXX” から車で “XX” 分</h6>
                <h6 class="font-sm-preview">・”XXXXXX” からバイクで “XX” 分</h6>
            </div> -->
            <!-- <hr class="seperator">
            <div class="section-1">
                <h4 class="job-title">労働条件通知書</h4>
                <h6 class="font-sm-preview">000 0000 0000</h6>
            </div> -->
            <hr class="seperator">
            <div class="section-1">
                <!-- transportstion -->
                    <h4 class="job-title mb-1">交通手段</h4>
                    <div class="d-flex flex-wrap" style="gap:4px;overflow: hidden;width: 100%;">
                        <div class="tag-preview" *ngFor="let tran of jobDetails?.transportation">{{tran}}</div>
                    </div>  
            </div>
            <hr class="seperator">
            <div class="section-1">
                <!-- precaution -->
                <h4 class="job-title mb-1">注意事項</h4>
                <textarea class="preview-textarea font-sm-preview" #precaution readonly style.height="{{precaution?.scrollHeight}}px !important">{{jobDetails?.precautions}}</textarea>
            </div>
            <hr class="seperator">
        </div>
        <div class="bottom-div-fixed">
            <div div fxLayout="row" fxLayoutAlign="space-around center" style="width: 100%;">
                <img style="width:29.55px;height:26.21px;" src="/assets/icons/heart-preview.svg" alt="">
                <button class="btn-preview-lg">応募する</button>
            </div>
            <div class="nav-line"></div>
        </div>
    </div>
</div>


</div>
