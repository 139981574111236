import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { GeneralService } from 'app/auth/service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  public lang
  constructor(private generalService:GeneralService){
    this.generalService.currentLanguage.subscribe(lang=>this.lang=lang)
  }
  action(key){
    this.generalService.action.next(key)
  }
}
