import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'app/auth/service';
import { ApiConstant } from 'app/shared/api-constents';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  @Input()close:any
  constructor(
    private apiService:ApiService,
  ) { }

  ngOnInit(): void {
    this.updateChatOnlineStatus()
  }

  updateChatOnlineStatus(){
    let body={
      status:false,
    }
    this.apiService.ExecutePost(this.apiService.baseUrl+ApiConstant.chatUpdateOnlineStatus,body)
    .subscribe((response)=>{
         console.log("updateChatOnlineStatus",response)
    },(error)=>{
      console.log("updateChatOnlineStatus error",error)
    })
  }

}
