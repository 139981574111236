import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/auth/service';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { ApiConstant } from 'app/shared/api-constents';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Interface
interface notification {
  // messages: [];
  // systemMessages: [];
  // system: Boolean;
  count: Number
  data: []
  unread_notifications: Number
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  private unsubscribe = new Subject<void>();
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private router: Router,
    private apiService: ApiService) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
  }

  DetailPage(message) {
    let id = message?.createdBy
    let app_id = ""
    // let jobId=""
    switch (message?.type) {
      case "cancel_application":
        this.router.navigateByUrl(`/care_provider/home/part-time-job-view/${message?.job}`);
        break;
      case "worker_cancel_application":
        this.router.navigateByUrl(`/care_provider/home/care-worker-profile/${id}/${app_id}`);
        break;
      case "user_job_applied":
        this.router.navigateByUrl(`/care_provider/recruitment-management/job-application-list`);;
        break;
      case "application_confirmation_status":
        this.router.navigateByUrl(`/care_provider/home/list-of-applicants/${message?.job}`);;
        break;
      case "reward_correction_approval":
        this.router.navigateByUrl('/care_provider/attendance-management/reward-reruest-list');;
        break;
      case "REWARD_CORRECTION_REQUEST":
        this.router.navigateByUrl('/care_provider/attendance-management/reward-reruest-list');
        break;
      case "Announcement":
        this.router.navigateByUrl('/care_provider/announcement');
        break;
      case "id_status":
        this.router.navigateByUrl(`/care_provider/home/care-worker-profile/${id}/${app_id}`)
        break;
      case "upload_proof":
        this.router.navigateByUrl('/care_provider/home/calender')
        break;
      case "parttime_job_reminder":
        this.router.navigateByUrl(`/care_provider/home/list-of-applicants/${message?.job}`)
        break;
      case "auto_REWARD_CORRECTION_REQUEST":
        this.router.navigateByUrl('/care_provider/attendance-management/reward-reruest-list')
        break;
      case "suspend_fulltime_job":
        this.router.navigateByUrl('/care_provider/recruitment-management/create-fulltime-job')
        break;
      case "reviewed":
        this.router.navigateByUrl('/care_provider/review')
        break;
      case "AUTO_REWARD_CORRECTION_REQUEST":
        this.router.navigateByUrl('/care_provider/attendance-management/reward-reruest-list')
        break;
      case "absentism_approval_status":
        this.router.navigateByUrl('/care_provider/attendance-management/todays-work-schedule')
        break;
      case "withdraw_job":
        this.router.navigateByUrl(`/care_provider/home/part-time-job-view/${message?.job}`)
        break;
      case "qualification_notification":
        this.router.navigateByUrl('/care_provider/home/calender')
        break;
      case "application_disapproved":
        this.router.navigateByUrl(`/care_provider/home/list-of-applicants/${message?.job}`)
        break;
      case "application_approved":
        this.router.navigateByUrl(`/care_provider/home/list-of-applicants/${message?.job}`)
        break;
      case "worker_absentism_report":
        this.router.navigateByUrl('/care_provider/attendance-management/todays-work-schedule')
        break;
      case "interview_report":
        this.router.navigateByUrl('/care_provider/recruitment-management/job-application-list')
        break;
    }
    this.apiService
      .ExecutePut(`${this.apiService.baseUrl + ApiConstant.readNotification}/${message?._id}`, {})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: any) => {
          console.log("res", response)
          this._notificationsService.getNotificationsData()
        });
  }
}
