<div fxLayoutAlign="center center" fxLayout="column" style="width:100%;padding: 40px;"> 
    <a href="javascript:void(0);" class="brand-logo">
        <!-- <h2 class="brand-text text-primary ml-1" style="font-weight:bolder;margin-bottom:20px">KAIGO</h2> -->
        <img src="/assets/icons/kaigo-logo.svg"/>
      </a>
    <h4 class="title">{{"settings_register_care_providers_profile_information_from_account_settings1"|translate}}</h4>
    <h4 class="title" style="margin-bottom:5px;">{{"settings_register_care_providers_profile_information_from_account_settings2"|translate}}</h4>
    <p class="subtitle" style="margin:8px 0px 6px 0px;">{{"by_registering_your_business_profile_information_you_will_be_able_to_post_part_time_job_and_full_time_job_openings1"|translate}}</p>
    <p class="subtitle" style="line-height: 8px;">{{"by_registering_your_business_profile_information_you_will_be_able_to_post_part_time_job_and_full_time_job_openings2"|translate}}</p>
    <button class="warning-nav-btn" (click)="close()">{{"register"|translate}}</button>
   
</div>
