import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'app/auth/service';
import { Subject } from 'rxjs';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  @Input() close: any
  qrData: string = JSON.stringify({
    uid: this.generalService.getuid,
    _id: JSON.parse(localStorage.getItem('currentUser'))._id
  });
  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {
    console.log()
  }
  downloadQRCode() {
    // qrcodediv is the html element which has to be converted to PDF
    html2canvas(document.querySelector("#qrcodediv")).then(canvas => {

      let pdf = new jspdf("p", "mm", "a4");
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
      pdf.save('qrcode.pdf');
      this.close();

    });

  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
