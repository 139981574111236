<div class="modal-header" style="position: relative;">
  <!-- <div type="button" fxLayoutAlign="end" style="width:100%" (click)="close('Cross click')" aria-label="Close">
    <img src="../../../../assets/icons/new-close-icon.svg" alt="" style="width:25px"/>
  </div> -->
  <!-- <div type="button" fxLayoutAlign="end" style="width:100%;"> -->
    <img src="../../../../assets/icons/new-close-icon.svg"  (click)="close('Cross click')" aria-label="Close" alt="" class="close-button" />
  <!-- </div> -->
</div>
<div class="modal-body my-3" tabindex="0">
  <div id="qrcodediv">
  <div class="d-flex justify-content-center panel mt-3">
    <qrcode [qrdata]="qrData" [width]="157" [errorCorrectionLevel]="'M'"></qrcode>

  </div>
  <div class="qr-label text-center mt-2">
    {{"qr_label"|translate}}
  </div>
  <div class="qr-note text-center pt-1">
    {{"qr_note_2"|translate}}
  </div>
  </div>
  <div class="btn-download text-center mt-2">
    <button type="button" class="btn btn-primary" (click)="downloadQRCode()">
      {{"qr_download_btn"|translate}}
    </button>
  </div>
</div>