import { Injectable } from '@angular/core';
import { ApiService } from 'app/auth/service';
import { ApiConstant } from 'app/shared/api-constents';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
 public apiData :any;
 public onApiDataChange: BehaviorSubject<any>;

 constructor(
   private apiService:ApiService
   ) {
   this.onApiDataChange = new BehaviorSubject('');
 }
 
 getAnnouncementCount() {
  return new Promise((resolve, reject) => {
    this.apiService.ExecuteGet(this.apiService.baseUrl+ApiConstant.announcementCount)
    .subscribe((response: any) => {
      this.apiData = response?.result?.count;
      this.onApiDataChange.next(this.apiData);
      resolve(this.apiData);
    }, reject);
  });
}

}
