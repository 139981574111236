import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (this._authenticationService.getToken && !request.url.includes('amazonaws') && !request.url.includes('securetoken.googleapis.com')) {
      this._authenticationService.checkTokenValidation()
      request = request.clone({
        setHeaders: {
          Authorization: this._authenticationService.getToken
        }
      });
    }
    else if(this._authenticationService.getToken && request.url.includes('securetoken.googleapis.com')){
      request = request.clone({
        setHeaders: {
          'content-type':'application/x-www-form-urlencoded'
        }
      });
    }
    
    return next.handle(request)
    .pipe(
      catchError(err => {
        // throwError
        if(err?.status===302){
          // this.router.navigate(['auth/session-expired'])
          this._authenticationService.logout()
        }
        const error = err.statusText;
        return throwError(err);
      })
    );
  }
}
