import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-profile-warning',
  templateUrl: './profile-warning.component.html',
  styleUrls: ['./profile-warning.component.scss']
})
export class ProfileWarningComponent implements OnInit,OnDestroy {
  config
  private _unsubscribeAll: Subject<any>;
  @Input()close:any
  constructor(
    private _coreConfigService: CoreConfigService,
  ) { }

  ngOnInit(): void {

    
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
