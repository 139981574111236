import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'app/auth/service';
import { ApiConstant } from 'app/shared/api-constents';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData :any;
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private apiService:ApiService
    ) {
    this.onApiDataChange = new BehaviorSubject('');
    // this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiService.ExecuteGet(this.apiService.baseUrl+ApiConstant.notification)
      .subscribe((response: any) => {
        this.apiData = response?.result;
        console.log("notification",response)
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }
}
