<div>
    <div class="modal-header pb-0" style="background-color: white;">
        <button class="close-btn close" (click)="onCancel()"  fxLayout="row"
            fxLayoutAlign="center center">
            <span aria-hidden="true" style="font-size: 19px">&times;</span>
        </button>
    </div>
    <div class="container p-4 pl-5 pr-5">
        <div class="row">
            <div class="col-md-12  ">
                <div class="title text-center">
                    採用面談結果の報告
                </div>
                <div class="form-group pt-2 px-2">
                    <div style="width: 100%;">
                        <form class="form-group" [formGroup]="reportForm">
                            <label for="title" class="form-label">{{"employment_status"|translate}}<span
                                    style="color:#EE5F40">*</span></label>
                            <div class="row pl-1" fxLayoutGap="50px">
                                <div class="form-check" fxLayoutGap="10px" fxLayoutAlign="start center">
                                    <input class="form-check-input " type="radio" formControlName="recruitment_status"
                                        value="hired" name="recruitment_status" id="flexRadioDefault3">
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        {{"employment_offer"|translate}}
                                    </label>
                                </div>
                                <div class="form-check" fxLayoutAlign="start center">
                                    <input class="form-check-input " type="radio" formControlName="recruitment_status"
                                        value="not_hired" name="recruitment_status" id="flexRadioDefault4">
                                    <label class="form-check-label" for="flexRadioDefault4">
                                        {{"rejected"|translate}}
                                    </label>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="this.reportForm.value.recruitment_status=='hired'" [formGroup]="approveForm">
                            <div class="form-group">
                                <label for="title" class="form-label">{{"employment_status"|translate}}<span
                                        style="color:#EE5F40">*</span></label>
                                <div class="row pl-1" fxLayoutGap="10px">
                                    <div class="form-check">
                                        <input class="form-check-input " type="radio" formControlName="employment_status"
                                            value="regular" name="employment_status" id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            {{"regular_employee"|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check ml-2">
                                        <input class="form-check-input " type="radio" formControlName="employment_status"
                                            value="part_time" name="employment_status" id="flexRadioDefault2">
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            {{"part"|translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group date-picker reward-datepicker custom-readonly" style="width: 100%">
                                <label class="form-label">{{"the_day_you_start_to_work"|translate}}<span
                                        style="color: #ee5f40">*</span></label>
                                <ng2-flatpickr [config]="timePickerOptions" #startDatePicker style="height: 40px;"  class="ng2-flatpickr-form-control" formControlName="start_date">
                                </ng2-flatpickr>
                            </div>
                            <div class="form-group mt-2">
                                <label class="form-label">{{"type_of_occupation"|translate}}<span
                                        style="color: #ee5f40">*</span></label>
                                <input type="text" class="form-control" formControlName="type_of_occupation" />
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{"place_of_employment"|translate}}</label>
                                <div class="input-grey" formControlName="officeName">{{care_provider}}</div>
                            </div>
                        </form>
                        <form *ngIf="this.reportForm.value.recruitment_status=='not_hired'">
                            <div class="form-group mt-2" [formGroup]="rejectForm">
                                <label class="form-label">{{"reason_for_rejection"|translate}}<span
                                        style="color: #ee5f40">*</span></label>
                                <textarea class="form-control" maxlength="300" formControlName="reason_for_rejection"  ></textarea>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="mt-3 px-2">
            <button fxFlex (click)="onCancel()" type="button"
                class="btn btn-cancel-sidebar">{{"cancel"|translate}}</button>
            <button fxFlex (click)="onConfirm(approvalModal,rejectionModal)" type="button" [disabled]="reportForm.invalid ||(reportForm.valid && (approveForm.invalid && rejectForm.invalid))"
                class="btn-confirm-sidebar">{{"report_interview"|translate}}</button>
        </div>
    </div>
</div>


<div class="modal-dark d-inline-block p-2">
    <ng-template #approvalModal let-modal>
        <div class="popup-inner">
            <div class="modal-header" style="background-color: #FFFF">
                <h5 class="popup-title" id="myModalLabel160">応募者の面談結果を<br>採用で確定してもよろしいですか？</h5>
            </div>
            <!-- <div class="popup-body" tabindex="0" ngbAutofocus>
                応募者の面談結果を採用で確定してもよろしいですか？
            </div> -->
            <div class="pl-3 pr-3" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="center center"
                fxLayoutGap="10px" style="padding-top: 14px;">
                <button type="button" class="btn popup-cancel-btn btn-width" rippleEffect
                    (click)="modal.dismiss('Cross click')">
                    {{"cancel"|translate}}
                </button>
                <button type="button" class="btn btn-width popup-submit-btn" (click)="modal.close('confirm')"
                    rippleEffect>
                    {{"confirm"|translate}}
                </button>
            </div>
        </div>
    </ng-template>
    <ng-template #rejectionModal let-modal>
        <div class="popup-inner">
            <div class="modal-header" style="background-color: #FFFF">
                <h5 class="popup-title" id="myModalLabel160">応募者の面談結果を<br>不採用で確定してもよろしいですか？</h5>
            </div>
            <!-- <div class="popup-body" tabindex="0" ngbAutofocus>
                応募者の面談結果を採用で確定してもよろしいですか？
            </div> -->
            <div class="pl-3 pr-3" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="center center"
                fxLayoutGap="10px" style="padding-top: 14px;">
                <button type="button" class="btn popup-cancel-btn btn-width" rippleEffect
                    (click)="modal.dismiss('Cross click')">
                    {{"cancel"|translate}}
                </button>
                <button type="button" class="btn btn-width popup-submit-btn" (click)="modal.close('confirm')"
                    rippleEffect>
                    {{"confirm"|translate}}
                </button>
            </div>
        </div>
    </ng-template>
</div>